import { HomePageQuery } from '.gql/graphql';
import Subscription from '@/components/common/subscription';
import PageBuilder from '@/components/pagebuilder/pageBuilder';
import Container from '@/components/ui/container';
import BoxLoader from '@/components/ui/loaders/box-loader';
import { withStaticProps } from '@/server/app';
import { serverEnv } from '@/server/env';
import { apolloClient, gql } from '@/utils/apollo';
import Layout from '@components/layout/layout';
import Divider from '@components/ui/divider';

interface HomePageProps {
  cmsPage: HomePageQuery['cmsPage'];
}

export default function HomePage(props: HomePageProps) {
  const { cmsPage } = props;

  return (
    <>
      <Divider className="mb-0 pt-0" />
      <PageBuilder
        pagebuilder={cmsPage?.pagebuilder}
        loader={
          <div className="fixed inset-0 flex items-center justify-center bg-white">
            <div className="cms-content">
              <div className="mb-10 flex space-x-8">
                <BoxLoader className="h-[500px] w-1/4" />
                <BoxLoader className="h-[500px] w-1/2" />
                <BoxLoader className="h-[500px] w-1/4" />
              </div>
            </div>
          </div>
        }
      />
      <Container className="mt-9 md:mt-11 lg:mt-16">
        <Subscription />
      </Container>
    </>
  );
}

HomePage.Layout = Layout;

export const getStaticProps = withStaticProps<HomePageProps>(async ({ store }) => {
  const homePageQuery = apolloClient.query({
    query: gql(`
      query HomePage($identifier: String!) {
        cmsPage(identifier: $identifier) {
          identifier
          content_heading
          pagebuilder {
            html
            components
          }
        }
      }
    `),
    variables: {
      identifier: store.cms_home_page,
    },
  });

  return {
    props: {
      cmsPage: (await homePageQuery).data.cmsPage,
    },
    revalidate: serverEnv.ISG_REVALIDATE_PAGE_INDEX,
  };
});
